<template>
  <div>
    <h5 :dir="lang === 'en'?'ltr':'rtl'">
      <strong>{{ $t("booking.board-basis") }} : </strong>
      {{ ` ${basisName} - ${description}` }}
    </h5>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['room'],
  data() {
    return {
      basisName: '',
      description: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  created() {
    this.basisName = this.$t(`basis-name.${this.room.basisCode.toLowerCase()}`);
    this.description = this.room.roomClassName;
  },
};
</script>

<style scoped>
@media (max-width: 479px) {
  .hotel_details_contain_box h5 {
    font-size: 15px;
  }
}
</style>
